<template>
    <div class="option_terminal">
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <h3>Liste des machines</h3>
                <vs-button color="success" type="filled" v-on:click="openAddMachine" icon-pack="feather" icon="icon-plus" >Ajouter</vs-button>
            </vs-col>
        </vs-row>

        <vs-divider/>
        <div>
            <div v-for="machine in list_machine" :key="machine.uid">
                <vx-card>
                    <vs-row>
                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-xs="12">
                            
                            <div class="con-s">
                                <vs-switch color="success" v-model="machine.active" class="mr-4" v-on:change="changeActif( machine.uid, machine.active )">
                                    <span slot="on">Actif</span>
                                    <span slot="off">Inactif</span>
                                </vs-switch>
                            </div>

                            <b class="cursor" @click="toPage('/admin/option/machine/'+machine.uid)" >
                            {{ machine.name }} 
                            <template v-if="machine.groupe.length>0">
                                / {{ machine.groupe }}
                            </template>
                            </b>
                        </vs-col>
                    </vs-row>
                </vx-card>
                <br/>
            </div>
            <p v-if="list_machine.length==0" class="center">Aucune machine</p>
        </div>
        <AddMachine ref="addMachine" />
    </div>
</template>



<style lang="scss">
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'

import Machine from '@/database/models/machine'

import AddMachine from '@/components/machine/add.vue'

export default {
    components:{
        AddMachine,
	},
    data(){
        return{
            list_machine:[],
        }
	},
	mounted:function()
	{
        //autoRefresh
		autoRefresh.SetCallback(()=>{
            //refresh
            Machine.getTabAllMemoryClone((list_machine)=>
            {
                //triage par groupe
                list_machine.sort(function(a, b)//trier valeur ( ATTENTION CREE DES DOUBLONS )
                {
                    var ca  = a.groupe.toUpperCase();
                    var cb  = b.groupe.toUpperCase();
                    return ('' + ca).localeCompare(cb);
                });

                this.list_machine = list_machine
            })
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            autoRefresh.refresh()
        }
    },
    methods:{
		openAddMachine(){
            this.$refs.addMachine.openPopup(()=>{
                autoRefresh.refresh();
            })
        },
        toPage( url )
		{
			this.$router.push(url).catch(() => {});
		},
        changeActif( uid, value )
        {
            // console.log(uid)
            Machine.update(uid, {
                'active':value
            })
            .then(()=>{
                //alert("Modification enregistrée");
            })
        }
    }
};

</script>